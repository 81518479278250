import Head from 'next/head';
import { NextSeo } from 'next-seo';

//screen
import { Login } from '/screens/';

const LoginPage = () => {
  return (
    <>
      <Head>
        <meta
          name="keywords"
          content="Login, login page, loadsmart login, opendock login, shipper guide login, navtrac login"
        />
      </Head>
      <NextSeo
        nofollow={process.env.NOINDEX_FLAG}
        noindex={process.env.NOINDEX_FLAG}
        title="Login Page"
        description="..."
        canonical="https://loadsmart.com/login/"
        openGraph={{
          url: 'https://loadsmart.com/login/',
        }}
      />

      <Login />
    </>
  );
};

export default LoginPage;
